import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Module from '../components/Module';
import Text from '../components/Text';

import FingerSwipe from '@src/assets/img/finger-swipe.svg';


const useStyles = makeStyles((theme) => ({
  collectionListImageModule: {},
  benefitContainer: {
    padding: theme.spacing(2, 4, 2, 4),
    backgroundColor: theme.customPalette.greyClear,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {},
  },

  title: {
    marginBottom: theme.spacing(4), // FIX ** 8 was too much
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },

  wrapper: {
    overflow:"hidden",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      overflowX: 'scroll !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track' : {
      background:  'white'
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#f29100',
      borderRadius: '10px',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background:  '#f29100'
    },
    '&::-webkit-scrollbar':  {
      width: "20px"
    }
  },
  imagex: {
    maxWidth: "100%",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
    },
  },
  
  iconx: {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },

}));

const CollectionListImageModule = ({ title, picture }) => {
  const classes = useStyles();

  return (
    <Module className={classes.collectionListImageModule}>
      <Box className={classes.benefitContainer}>
        <Text className={classes.title} overline align='center' component="h2" variant='titleXXXL' weight='bold'>
          {title}
        </Text>
          <div className={classes.wrapper} >
              <img src={picture.src} className={classes.imagex}  />
          </div> 
          <img src={FingerSwipe} className={classes.iconx}  />
      </Box>
    </Module>
  );
};

const defaultProps = {
  title: '',
  picture: {
    src: "",
  }
};

CollectionListImageModule.defaultProps = defaultProps;

export default CollectionListImageModule;
