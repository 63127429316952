import React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { Hidden } from "@material-ui/core"

import Module from "../components/Module"
import Text from "../components/Text"
import FluidImage from "../components/FluidImage"

const useStyles = makeStyles(theme => ({
  genericContent: {
    margin: 0,
  },
  container: {
    width: "85%",
  },
  pageTitle: {
    marginTop: 120,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    paddingTop: 30,
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  sectionImage: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
    },
  },
}))

const GenericContentModule = props => {
  const { title, text, backgroundColor, image, imageMobile, icon } = props
  const classes = useStyles()

  return (
    // get background color from api
    <Module
      className={clsx(classes.genericContent)}
      classes={{ container: classes.container }}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}
    >
      <Box>
        {title && (
          <>
            {!text ? (
              <Text
                className={classes.pageTitle}
                align="center"
                variant="specialTitleL"
                component="h1"
              >
                {title}
              </Text>
            ) : (
              <Text
                className={classes.sectionTitle}
                overline
                variant="titleXL"
                weight="bold"
              >
                {title}
              </Text>
            )}
          </>
        )}

        {icon && (
          <FluidImage
            className={classes.image}
            sources={icon?.sources}
          ></FluidImage>
        )}
      </Box>

      <div className={classes.sectionImage}>
        {text && (
          <Text className={classes.text} component="div" variant="bodyM">
            {text}
          </Text>
        )}
        {image && (
          <Hidden smDown>
            <FluidImage
              className={classes.image}
              sources={image?.sources}
            ></FluidImage>
          </Hidden>
        )}
        {imageMobile && (
          <Hidden mdUp>
            <FluidImage
              className={classes.image}
              sources={imageMobile?.sources}
            ></FluidImage>
          </Hidden>
        )}
      </div>
    </Module>
  )
}

export default GenericContentModule
