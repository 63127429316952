import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import Module from "../components/Module"
import Text from "../components/Text"
import Overlay from "../components/Overlay"
import { GTMEvent, GTMEventAddToCart } from "../utils/gtm"
import Picture from "../components/Picture"
import EcommerceMonthlyItemModule from "./EcommerceMonthlyItemModule"
import { useTranslation } from "react-i18next"
import "../style/FiguresGridAutomaticModule.css"
import SnakeCommerceCart from "../../static/cart"
import EcommerceMoreInfo from "../components/EcommerceMoreInfo"

const useStyles = makeStyles(() => ({}))

const isEcommerceItemActive = activationDate => {
  // if (
  //   activationDate === "2024-12-05" ||
  //   activationDate === "2025-01-04" ||
  //   activationDate === "2025-02-03" ||
  //   activationDate === "2025-03-05"
  // ) {
  //   activationDate = "2024-11-05"
  // }
  const inputDate = new Date(activationDate)
  const today = new Date()
  return inputDate.getTime() < today.getTime()
}

const formatDateToDayMonth = (date, repo) => {
  const inputDate = new Date(date)
  const options = { day: "numeric", month: "short" }
  const formattedDate = inputDate.toLocaleDateString(
    repo.seo.siteLanguage,
    options
  )
  return formattedDate
}

function formatPrice(price) {
  if (typeof price === "string") {
    return price.replace(",", ".")
  }
  return price
}

const FiguresGridAutomatic = props => {
  const classes = useStyles()
  const {
    title,
    details,
    popup_title,
    distribution_type,
    cta,
    repo,
    ...others
  } = props
  const [openOverlay, setOpenOverlay] = useState(false)
  const [currentFigure, setCurrentFigure] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  const distributionType = distribution_type.field_value
  const { t } = useTranslation()

  const handleFigureClick = (figure, index) => {
    setCurrentIndex(index)
    setOpenOverlay(true)
    setCurrentFigure(figure)

    if (figure.name && others.repo) {
      GTMEvent(others.repo, "click_figure", figure.name)
    }
  }

  const handleOverlayClose = () => {
    setOpenOverlay(false)
  }

  return (
    <Module
      className={clsx(classes.FiguresGridAutomatic, "FiguresGridAutomatic")}
    >
      <Text
        align="center"
        component="h2"
        variant="titleXXXL"
        weight="bold"
        overline
        gutterBottom
      >
        {title.field_value}
      </Text>
      <Box className={clsx(classes.container, "container")}>
        {details.field_value.map((item, index) => {
          const activationDate =
            item.field_value[`figure_${distributionType}_date`]?.field_value ||
            false
          const isActive = isEcommerceItemActive(activationDate)
          return (
            <div className="m-1 p-1 background-color-grey card" key={index}>
              <div className="card-image">
                <Picture {...item?.field_value?.figure_image?.field_value} />
              </div>
              <div className="card-title">
                <div className="card-badge">
                  <span className="badge d-none out-of-stock-message">
                    {t("ecommerce.outOfStock")}
                  </span>
                  <span
                    className={`badge coming-soon-message ${
                      isActive ? "d-none" : ""
                    }`}
                  >
                    {t("ecommerce.comingSoon")}
                  </span>
                </div>
                <Text component="span" variant="titleXL" className="title-card">
                  {item?.field_value?.figure_title_html?.field_value}
                </Text>
              </div>
              <div className="card-actions">
                {isActive ? (
                  <ActiveCardActions
                    item={item}
                    distributionType={distributionType}
                    handleFigureClick={() => handleFigureClick(item, index)}
                    t={t}
                    classes={classes}
                    repo={repo}
                  />
                ) : (
                  <UnavailableCardActions
                    activationDate={activationDate}
                    handleFigureClick={() => handleFigureClick(item, index)}
                    repo={repo}
                    t={t}
                  />
                )}
              </div>
            </div>
          )
        })}
      </Box>
      <Overlay
        stickyHeader
        title={popup_title?.field_value}
        fullScreen
        open={openOverlay}
        onClose={handleOverlayClose}
      >
        <EcommerceMoreInfo
          title={title}
          product={details}
          distributionType={distributionType}
          indexPicture={currentIndex}
          repo={repo}
          cta={cta}
          cart={SnakeCommerceCart}
        />
      </Overlay>
    </Module>
  )
}

const ActiveCardActions = ({
  item,
  distributionType,
  handleFigureClick,
  t,
  classes,
  repo,
}) => (
  <div className="card-actions__cta">
    <div className="cta-block">
      <div className="top-row">
        <div className="price">
          {formatPrice(
            item.field_value[`figure_${distributionType}_price`]?.field_value
          )}
        </div>
        <div
          className="link"
          onClick={() => GTMEvent(repo, "ecommerce_module", "more info")}
        >
          <Text
            component="span"
            variant="titleL"
            onClick={handleFigureClick}
            className="label-more-info"
          >
            {t("ecommerce.moreInfo")}
          </Text>
        </div>
      </div>
      <div className="bottom-row">
        <button
          data-sku={
            item.field_value[`figure_${distributionType}_code`]?.field_value
          }
          className={clsx(
            classes.buttonCart,
            "button-add-to-cart",
            "add-cart-item-trigger"
          )}
          disabled
        >
          <Text
            tag="span"
            className="container-text-add-to-cart"
            onClick={() => {
              GTMEvent(
                repo,
                "ecommerce_module",
                item?.field_value?.figure_cta_addtocart?.field_value
              )
              GTMEventAddToCart(
                repo ?? "",
                item.field_value[`figure_${distributionType}_code`] ?? "",
                item.field_value[`figure_${distributionType}_price`] ?? "",
                item.field_value[`figure_${distributionType}_currency_code`] ??
                  ""
              )
            }}
          >
            {item?.field_value?.figure_cta_addtocart?.field_value}
          </Text>
        </button>
      </div>
    </div>
  </div>
)

const UnavailableCardActions = ({
  activationDate,
  handleFigureClick,
  repo,
  t,
}) => (
  <div className="card-actions__unaivalability">
    <div className="top-row">
      <div className="availability-date">
        {`${t("ecommerce.labelAvailableDate")} ${formatDateToDayMonth(
          activationDate,
          repo
        )}`}
      </div>
      <div
        className="link"
        onClick={() => GTMEvent(repo, "ecommerce_module", "more info")}
      >
        <Text
          component="span"
          variant="titleL"
          onClick={handleFigureClick}
          className="label-more-info"
        >
          {t("ecommerce.moreInfo")}
        </Text>
      </div>
    </div>
  </div>
)

export default FiguresGridAutomatic
