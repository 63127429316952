import React from "react"
import PropTypes from "prop-types"
import Icon from "../components/Icon"
import Text from "../components/Text"
import Module from "../components/Module"
import { Container, Grid, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import clsx from "clsx"
import KlarnaPlacement from "../components/KlarnaPlacement"
import "../style/DeliveryPlanOfferModule.css"
import CtaButton from "../components/CtaButton"

const useStyles = makeStyles(theme => ({
  DeliveryPlanOfferModule: {
    "& .mainHeading": {
      "& .title": {
        fontWeight: 700,
      },
      "& .title + .subtitle": {
        marginTop: 20,
      },
    },

    "& .cards": {
      marginTop: 20,

      "& .container": {
        maxWidth: 1100,
      },

      "& .card": {
        fontFamily: "Poppins",
        position: "relative",
        padding: "30px 30px 15px",
        margin: "30px 15px",
        background: theme.customPalette.primaryGrey05,
        overflow: "hidden",
        transition: "transform .2s",
        borderBottom: "2px solid #662482",

        "& > .bannertitle": {
          whiteWpace: "nowrap",
          position: "absolute",
          top: 0,
          right: 0,
          padding: "5px 0",
          width: "200px",
          textAlign: "center",
          transform: "rotate(45deg) translate(30%, 0)",
          textTransform: "uppercase",
          fontSize: 12,
          boxSizing: "border-box",
          color: "#fff",
        },
        "& > .uptitle": {
          marginBottom: 10,
          color: "#662482",
          textTransform: "uppercase",
          "& > *": {
            fontFamily: "Poppins",
            fontWeight: 800,
            fontSize: 12,
          },
        },
        "& > .title": {
          marginTop: 0,
          marginBottom: 15,
          paddingRight: "15%",
          "& > *": {
            fontFamily: "Poppins",
            fontWeight: 800,
            lineHeight: "48px",
            fontSize: 38,
          },
        },
        "& > .points": {
          "& .content": {
            overflow: "hidden",
            height: 0,
            transition: "all .4s ease-out",
            listStyleType: "none",
            "& li": {
              listStyleType: "none",
              marginBottom: 5,
            },

            "& .icon": {
              width: 16,
              height: 16,
              marginRight: 10,
              verticalAlign: "sub",
            },

            "&.mobile_as_desktop:not(.default_zero)": {
              padding: "20px 10px 40px",
              borderTop: "2px solid #662482",
              opacity: 1,
              transition: "all .4s ease-out",
              height: "auto",
            },

            [theme.breakpoints.up("md")]: {
              "&:not(.default_zero)": {
                padding: "20px 10px 40px",
                borderTop: "2px solid #662482",
                opacity: 1,
                transition: "all .4s ease-out",
                height: "auto",
              },
            },

            "& li:not(.showDefaultPoint)": {
              overflow: "hidden",
              height: 0,
              transition: "all .4s ease-out",
            },

            "& .title": {
              padding: "15px 15px 0",
              textAlign: "center",
              fontWeight: 800,
              "& .d-flex": {
                justifyContent: "center",
                padding: "0 15px",
                "& img": {
                  display: "none",
                },
                "& div": {
                  margin: "0 !important",
                },
              },
            },
          },
          "&.opened .content": {
            padding: "20px 0 40px",
            borderTop: "2px solid #662482",
            opacity: 1,
            transition: "all .4s ease-out",
            height: "auto",
          },

          "&.opened .content li:not(.showDefaultPoint)": {
            opacity: 1,
            transition: "all .4s ease-out",
            height: "auto",
          },
        },
        "& > .cta": {
          marginBottom: 15,
          "& .btn": {
            fontFamily: "Poppins",
            fontWeight: 600,
            width: "100%",
            height: 48,
            maxWidth: "100%",
            justifyContent: "center",
            textDecoration: "unset",
          },
        },
        "& > .moreinfo": {
          marginBottom: 15,
          textAlign: "center",
          cursor: "pointer",
          "& span": {
            textDecoration: "underline",
            lineHeight: "16px",
            color: "#662482",
          },
          "& img": {
            marginLeft: 6,
            width: 12,
            height: 12,
            webkitTransform: "rotate(0)",
            transform: "rotate(0)",
            transition: "all .4s ease-out",
          },
          "&.opened img": {
            webkitTransform: "rotate(-180deg)",
            transform: "rotate(-180deg)",
            transition: "all .4s ease-out",
          },
        },

        [theme.breakpoints.up("md")]: {
          "&:hover": {
            transform: "scale(1.1)",
          },
        },
      },
    },
  },
  marginModule: {
    margin: "32px 0px 32px 0px !important",
  },
}))

const DeliveryPlanOfferModule = props => {
  const {
    title,
    sub_title,
    linecolor,
    primary,
    text_color,
    label_color,
    repo,
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Module
      className={clsx(classes.DeliveryPlanOfferModule, "DeliveryPlanOffer")}
      style={{
        backgroundColor: linecolor.field_value || "#fff",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          className="mainHeading"
          container
          direction="row"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            {title.field_value && (
              <Text
                className="title"
                align="center"
                component="h3"
                variant="titleXXXL"
                weight="bold"
                overline
                style={{ color: text_color.field_value || "#fff" }}
              >
                {title.field_value}
              </Text>
            )}
            {sub_title.field_value && (
              <Text
                className="subtitle"
                align="center"
                component="h3"
                variant="bodyM"
                style={{ color: text_color.field_value || "#fff" }}
              >
                {sub_title.field_value}
              </Text>
            )}
          </Grid>
        </Grid>
        <Grid
          className="cards"
          container
          direction="row"
          alignItems="center"
          justifyContent={"center"}
        >
          {primary.field_value.map((item, i) => (
            <Card
              key={i}
              {...item.field_value}
              label_color={label_color}
              repo={repo}
            />
          ))}
        </Grid>
      </Container>
    </Module>
  )
}

const Card = props => {
  const {
    up_title,
    banner_title,
    title,
    points_to_show,
    primary,
    cta,
    more_info_label,
    less_info_label,
    mobile_expanded_card,
    gtm_value,
    label_color,
    repo,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [showDefaultPoints, setshowDefaultPoints] = React.useState(
    Number(points_to_show?.field_value)
  )
  const [cardIsClosed, setCardIsClosed] = React.useState(true)

  React.useEffect(() => {
    // card is closed
    if (isMobile) {
      setCardIsClosed(
        !mobile_expanded_card.field_value &&
          primary.field_value.length > showDefaultPoints
      )
    } else {
      setCardIsClosed(primary.field_value.length > showDefaultPoints)
    }

    // i titoli non devono contare
    let fixShowDefaultPoints = showDefaultPoints
    for (
      let index = 0;
      index < primary.field_value.length && index < fixShowDefaultPoints;
      index++
    ) {
      const element = primary.field_value[index]
      if (element.field_value["is_title"].field_value) {
        fixShowDefaultPoints = fixShowDefaultPoints + 1
        setshowDefaultPoints(fixShowDefaultPoints)
      }
    }
  }, [])

  return (
    <Grid item xs={12} md={4}>
      <div className="card">
        {banner_title.field_value && (
          <div
            className="bannertitle"
            style={{ backgroundColor: label_color?.field_value }}
          >
            {banner_title.field_value}
          </div>
        )}

        {up_title.field_value && (
          <div className="uptitle">
            <Text component="h4" color="primary" gutterBottom>
              {up_title.field_value}
            </Text>
          </div>
        )}

        {title.field_value && (
          <div className="title">
            <Text component="h3" color="primary" gutterBottom>
              {title.field_value}
            </Text>
          </div>
        )}

        {primary?.field_value?.length > 0 && (
          <>
            <div className={`points ${!cardIsClosed ? "opened" : ""}`}>
              <ul
                className={`content 
          ${mobile_expanded_card.field_value ? "mobile_as_desktop" : ""}
          ${showDefaultPoints === 0 ? "default_zero" : ""}
          `}
              >
                {primary.field_value?.map((points, i) => {
                  return (
                    <li
                      key={i}
                      className={`
                ${i < showDefaultPoints ? "showDefaultPoint" : ""}
                `}
                    >
                      {points?.field_value?.is_klarna_web_messaging
                        ?.field_value ? (
                        <div className="d-flex align-items-center">
                          <Icon className="icon" name="bullet" />
                          <KlarnaPlacement
                            repo={repo}
                            moduleName={"DeliveryPlanOffer"}
                          />
                        </div>
                      ) : (
                        <div
                          className={`
                  ${points.field_value["is_title"].field_value ? "title" : ""}
                `}
                        >
                          <div className="d-flex align-items-center">
                            <Icon className="icon" name="bullet" />
                            <Text className="mt-1 ml-1 mr-0">
                              {points.field_value["item2-text"].field_value}
                            </Text>
                          </div>
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        )}

        {cta?.field_value?.title && (
          <div className="cta">
            <CtaButton
              className="btn"
              size="large"
              {...cta.field_value}
              trackingObj="delivery-plan"
              trackingAction="click_button"
              trackingLabel={`${
                gtm_value?.field_value || "DeliveryPlanOfferModule-cta"
              }`}
            >
              {cta.field_value.title}
            </CtaButton>
          </div>
        )}

        {(primary?.field_value.length > showDefaultPoints ||
          (isMobile && !mobile_expanded_card.field_value)) && (
          <div
            className={`moreinfo ${cardIsClosed ? "" : "opened"}`}
            onClick={() => setCardIsClosed(!cardIsClosed)}
          >
            <div className="d-flex align-items-start justify-content-center">
              <Text>
                {cardIsClosed
                  ? more_info_label?.field_value
                  : less_info_label?.field_value}
              </Text>
              <Icon className="icon" name="select_purple" />
            </div>
          </div>
        )}
      </div>
    </Grid>
  )
}

export default DeliveryPlanOfferModule

DeliveryPlanOfferModule.defaultProps = {
  title: {
    field_type: "text",
    field_value: "",
  },
  sub_title: {
    field_type: "text",
    field_value: "",
  },
  linecolor: {
    field_type: "color",
    field_value: "",
  },
  primary: {
    field_type: "repeater",
    field_value: [],
  },
}

DeliveryPlanOfferModule.propTypes = {
  title: PropTypes.object,
  sub_title: PropTypes.object,
  linecolor: PropTypes.object,
  primary: {
    up_title: PropTypes.object,
    banner_title: PropTypes.object,
    title: PropTypes.object,
    points_title: PropTypes.object,
    points_to_show: PropTypes.object,
    primary: PropTypes.object,
    cta: PropTypes.object,
    more_info_label: PropTypes.object,
    less_info_label: PropTypes.object,
    mobile_expanded_card: PropTypes.object,
    gtm_value: PropTypes.object,
  },
}
