import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Module from "../components/Module"
import "../style/CookieProListModule.css"

const useStyles = makeStyles(theme => ({
  tableCookie: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
  },
}))

const CookieProListModule = () => {
  const classes = useStyles()
  return (
    <Module>
      <div id="ot-sdk-cookie-policy" className={classes.tableCookie}></div>
    </Module>
  )
}
export default CookieProListModule
