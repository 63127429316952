import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Module from "../components/Module"
import { Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  containerButtonCookie: {
    width: "85%",
  },
}))

const CookieProButtonModule = props => {
  const { title } = props
  const classes = useStyles()
  return (
    <Module classes={{ container: classes.containerButtonCookie }}>
      <Box>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          {title.field_value}
        </button>
      </Box>
    </Module>
  )
}
export default CookieProButtonModule
